export default {
    type: 'Graph',
    filter_keys: [
        ['Retention Type', 'retention-filter'],
    ],
    graph_settings: {
        series_sort: 'x_series_keys',
        x_zoom_custom: 'current_FY'
    }
}
